import React, { PureComponent } from 'react';
import {Form, Input, Select} from 'antd';
import globalCompanyStore from '@/global_store/globalCompanyStore';
import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react';

@observer
export default class CustomerForm extends PureComponent {

  private form: FormInstance | null = null

  private queryParams = {
    userName: '',
    fullName: '',
    deptId: '',
    status: '',
  }

  state = {
    departmentId: '',
    disabled: true,
  }

  componentDidMount() {
    globalCompanyStore.findDeptAll();
    globalCompanyStore.findEmployeeList();
  }

  render() {

    const {disabled} = this.state;

    return (
      <div>
        <Form ref={r => this.form = r} labelCol={{span: 5}}>
          <Form.Item label="所在部门" name="depart" rules={
            [
              {
                required: true,
                message: '请选择部门'
              }
            ]
          }>
            <Select placeholder="选择部门"
              onChange={(value, options) => {
                this.queryParams.deptId = value as string;
                this.setState({
                  disabled: false,
                  departmentId: value,
                });
              }}>
              {globalCompanyStore.departmentList.map(item => {
                return <Select.Option value={item.departmentId} key={item.departmentId}>
                  {item.departmentName}
                </Select.Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item label="业务员" name="employeeId" rules={
            [
              {
                required: true,
                message: '请选择业务员'
              }
            ]
          }>
            <Select placeholder="选择业务员"
              disabled={disabled}
              loading={globalCompanyStore.employeeLoading}>
              {globalCompanyStore.employeeList.filter(item => item.departmentId === this.state.departmentId).map((item: IEmployeeType) => {
                return <Select.Option value={item.employeeId as string} key={item.employeeId}>
                  {item.fullName}
                </Select.Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item label="手机号" name="mobile" rules={[{
            required: true,
            message: '请填写手机号'
          }]}>
            <Input maxLength={11}/>
          </Form.Item>
          <Form.Item label="客户名称" name="customerName" rules={[{
            required: true,
            message: '请填写客户名称'
          }]}>
            <Input/>
          </Form.Item>
          <Form.Item style={{marginBottom: 0}} label="密码" name="password" rules={[{
            required: true,
            message: '请填写密码'
          }]}>
            <Input.Password placeholder="6-20位"/>
          </Form.Item>
        </Form>
      </div>
    )
  }

  validateFields = async () => {
    return await this.form?.validateFields();
  };
}
