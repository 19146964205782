import React, { PureComponent } from 'react';
import CustomerForm from './CustomerForm';
import md5 from '@/utils/md5';
import { Dialog, Filter, ViewTable, Layout, Button } from '@/components';
import { routerMap } from '@/router/agent_router';
import { showSuccess } from '@/components/message/message';
import WebApi, { YchApi } from '@/web/api';
import globalUserStore from '@/global_store/globalUserStore';
import { observer } from 'mobx-react';
import { IFilterField } from '@/components/filter/Filter';
import { LibAuthCode } from 'lib/Auth';

@observer
export default class Customer extends PureComponent<RouterPropsType> {

  private table: ViewTable | null = null
  private addForm: any
  private queryParams = {
    keyword: '',
    agentId: undefined,
  };

  state = {
    visible: false,
    editModalVisible: false,
    resetModalVisible: false,
  }

  render() {

    const { visible, editModalVisible, resetModalVisible } = this.state;

    const columns = [
      {
        title: '编号',
        dataIndex: 'customerId',
        align: 'center',
        width: 200,
      },
      {
        title: '客户名称',
        dataIndex: 'customerName',
        width: 200,
      },
      {
        title: '手机号',
        dataIndex: 'mobile',
        align: 'center',
        width: 150,
      },
      {
        title: '业务员',
        dataIndex: 'employeeName',
        width: 150,
      },
      {
        title: '注册时间',
        align: 'center',
        dataIndex: 'createdAt',
        width: 200,
      },
      {
        title: '最近登录',
        align: 'center',
        dataIndex: 'lastLoginDatetime',
        width: 200,
        render: value => value || '未登录',
      }];
  
    const fields: IFilterField[] = [
      {
        type: 'input',
        placeholder: '客户编号/手机号/名称',
        field: 'keyword',
        name: '搜索'
      }
    ];

    return (
      <Layout.Page>
        <Layout.Toolbar title="客户列表">
          {
            !globalUserStore.isYch() && <Button type="primary" authCode={LibAuthCode.customer.create} onClick={() => this.setState({visible: true})}>新增客户</Button>
          }
          <Button.Refresh onClick={() => {
            this.table?.refresh();
          }}/>
        </Layout.Toolbar>
        
        <Filter
          ych
          fields={fields}
          onChange={values => {
            this.queryParams = values as any;
            this.table?.reset(this.queryParams);
          }}
        />

        <ViewTable
          flex1
          ych
          ref={r => this.table = r}
          fetch={data => {
            if (globalUserStore.isYch()) {
              return YchApi.customer_paging(data);
            }
            return WebApi.customer_paging(data);
          }}
          rowKey={row => row.id}
          columns={columns as any}
          actionButtons={globalUserStore.isYch() ? [] : [
            {
              type: 'update',
              onClick: row => {
                this.props.history.push(routerMap['customer.update'].getPath(row.customerId))
              }
            },
            {
              type: 'delete',
              onClick: row => {
                this.clickDelete(row);
              }
            },
          ]}
        />

        <Dialog visible={visible} title="新增客户"
          full={false}
          onCancel={() => this.setState({ visible: false})}
          onOk={this.clickCreateCustomer}>
          <div>
            <CustomerForm ref={r => this.addForm = r}/>
          </div>
        </Dialog>
      </Layout.Page>
    )
  }

  clickCreateCustomer = async () => {
    try {
      const values = await this.addForm.validateFields();
      const { mobile, customerName, password, employeeId } = values;
      
      WebApi.customer_create({
        mobile,
        customerName,
        employeeId,
        password: md5.hex_md5(password)
      })
      .then(() => {
        this.setState({
          visible: false
        });
        showSuccess('保存成功');
        this.table?.reset();
      });
      
    } catch (e) {
      console.log(e);
    }
  }

  clickDelete = async (record) => {
    Dialog.confirm({
      title: '删除确认',
      content: `确认删除客户 “${record.customerName}” 吗？`,
      onOk: async () => {
        WebApi.customer_delete({
          customerId: record.customerId
        })
        .then(() => {
          showSuccess.delete();
          this.table?.reset(this.queryParams);
        })
      },
    });
  }
}
